
import { scans } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "ScanDescription",
  props: {
    scan: Object,
  },
  data() {
    return {
      edit: false,
      description: "",
    };
  },
  methods: {
    async updateScan() {
      if (!this.description) {
        this.$store.dispatch(Actions.DisplayError, "Please enter a scan description");
        return;
      }
      await this.$emit("updateScan", this.description);
      this.edit = false;
    },
  },
});
