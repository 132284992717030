
import { IScanDetail } from "@/api";
import Vue from "vue";

export default Vue.extend({
  name: "ScanFile",
  props: {
    file: Object,
    focusedFileId: Number,
  },
  data() {
    return {};
  },
  computed: {
    focused: function () {
      // return this.focusedFileId === (this.file as IScanDetail).id;
      return false
    },
  },
  methods: {},
});
