
import { IAssociate, IScanAnnotation, scans } from "@/api";
import { Actions, IState } from "@/store/models";
import { getDate, getTime, sizeInMB } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "ScanFileProperties",
  props: {
    scan: Object,
    file: Object,
  },
  data() {
    return {
      annotations: [] as IScanAnnotation[],
    };
  },
  computed: {
    company: function () {
      return this.$store.state.associate.company.name;
    },
    scan_size: function (): string {
      return sizeInMB(this.scan.scan_size);
    },
  },
  created() {
    this.getAnnotations();
  },
  watch: {
    scan: async function () {
      this.getAnnotations();
    },
  },
  methods: {
    lastUpdated():string {
      const date = this.scan.updated_datetime || this.scan.created_datetime;
      return getDate(date);
    },
    async getAnnotations() {
      try {
        this.annotations = (
          await scans.annotations.getByScan(this.scan.id)
        ).data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async addAnnotation() {
      const annotation = prompt("Please enter a note");
      if (annotation) {
        try {
          const response = await scans.annotations.add(
            this.scan.id,
            annotation
          );
          this.getAnnotations();
        } catch (e) {
          this.$store.dispatch(Actions.DisplayError, e);
        }
      }
    },
    async deleteAnnotation(id: number) {
      try {
        const response = await scans.annotations.delete(id);
        this.getAnnotations();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
