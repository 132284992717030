
import { IScan } from "@/api";
import { getDate, getTime } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "ScanCard",
  props: {
    scan: Object,
    focusedScanId: Number,
  },
  data() {
    return {};
  },
  computed: {
    focused: function () {
      return this.focusedScanId === (this.scan as IScan).id;
    },
  },
  methods: {
    lastUpdated(): string {
      const vm = this as any;
      const date = vm.scan.updated_datetime || vm.scan.created_datetime;
      return getDate(date) + " • " + getTime(date);
    },
  },
});
