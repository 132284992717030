
import { devices, IAssociate, IDevice } from "@/api";
import { Actions } from "@/store/models";
import {
  ScansFilterActions,
  ScansFilterMutations,
} from "@/store/modules/scansFilterModule";
import Vue from "vue";

export default Vue.extend({
  name: "ScansFilterChips",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    timePeriod: {
      get() {
        return this.$store.state.scansFilter.timePeriod;
      },
      set(timePeriod: { id: string; name: string }) {
        this.onTimePeriodChange(timePeriod);
      },
    },
    radar: {
      get() {
        return this.$store.state.scansFilter.radar;
      },
      set(radar: IDevice) {
        this.onRadarChange(radar);
      },
    },
    user: {
      get() {
        return this.$store.state.scansFilter.user;
      },
      set(user: IAssociate) {
        this.onUserChange(user);
      },
    },
  },
  methods: {
    onRadarChange(radar?: IDevice) {
      this.$store.dispatch(ScansFilterActions.OnFilterChange, {
        type: ScansFilterMutations.SetRadar,
        data: radar,
      });
    },
    onTimePeriodChange(timePeriod?: { id: string; name: string }) {
      this.$store.dispatch(ScansFilterActions.OnFilterChange, {
        type: ScansFilterMutations.SetTimePeriod,
        data: timePeriod,
      });
    },
    onUserChange(user: IAssociate) {
      this.$store.dispatch(ScansFilterActions.OnFilterChange, {
        type: ScansFilterMutations.SetUser,
        data: user,
      });
    },
    getUserName(user: IAssociate) {
      return `${user.first_name} ${user.last_name}`;
    },
  },
});
