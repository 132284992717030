
import { devices, IAssociate, IDevice } from "@/api";
import { Actions } from "@/store/models";
import {
  ScansFilterActions,
  ScansFilterMutations,
} from "@/store/modules/scansFilterModule";
import ScansFilterChips from "@/components/cloudFolder/ScansFilterChips.vue";
import Vue from "vue";

export default Vue.extend({
  name: "ScansFilter",
  components: {
    ScansFilterChips,
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timePeriods: [
        { id: "today", name: "Today" },
        { id: "yesterday", name: "Yesterday" },
        { id: "this_week", name: "This Week" },
        { id: "last_week", name: "Last Week" },
        { id: "this_month", name: "This Month" },
        { id: "last_month", name: "Last Month" },
        { id: "this_year", name: "This Year" },
        { id: "last_year", name: "Last Year" },
      ],
      radars: [] as IDevice[],
    };
  },
  computed: {
    users: function () {
      return this.$store.state.company.associates;
    },
    getDrawer: {
      get() {
        return this.drawer;
      },
      set(drawer) {
        return drawer;
      },
    },
    timePeriod: {
      get() {
        return this.$store.state.scansFilter.timePeriod;
      },
      set(timePeriod: { id: string; name: string }) {
        this.$store.dispatch(ScansFilterActions.OnFilterChange, {
          type: ScansFilterMutations.SetTimePeriod,
          data: timePeriod,
        });
      },
    },
    radar: {
      get() {
        return this.$store.state.scansFilter.radar;
      },
      set(radar: IDevice) {
        this.$store.dispatch(ScansFilterActions.OnFilterChange, {
          type: ScansFilterMutations.SetRadar,
          data: radar,
        });
      },
    },
    user: {
      get() {
        return this.$store.state.scansFilter.user;
      },
      set(user: IAssociate) {
        this.$store.dispatch(ScansFilterActions.OnFilterChange, {
          type: ScansFilterMutations.SetUser,
          data: user,
        });
      },
    },
  },
  created() {
    this.getRadars();
  },
  methods: {
    onInput(isOpen: boolean) {
      this.$emit("toggleDrawer", isOpen);
    },
    async getRadars() {
      try {
        const response = await devices.getAll();
        this.radars = response.data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    getUserName(user: IAssociate) {
      return `${user.first_name} ${user.last_name}`;
    },
  },
});
