
import { IScan, IScanDetail, IScanFileType, scans, storage } from "@/api";
import { Actions, ScansActions } from "@/store/models";
import Vue from "vue";
import ScanCard from "@/components/cloudFolder/ScanCard.vue";
import TextDivider from "@/components/common/TextDivider.vue";
import ScanDescription from "@/components/cloudFolder/ScanDescription.vue";
import ScanFile from "@/components/cloudFolder/ScanFile.vue";
import ScanFileProperties from "@/components/cloudFolder/ScanFileProperties.vue";
import ScansFilter from "@/components/cloudFolder/ScansFilter.vue";
import { ScansFilterActions } from "@/store/modules/scansFilterModule";
import ScansFilterChips from "@/components/cloudFolder/ScansFilterChips.vue";

export default Vue.extend({
  name: "CloudFolder",
  components: {
    TextDivider,
    ScanCard,
    ScanDescription,
    ScanFile,
    ScanFileProperties,
    ScansFilter,
    ScansFilterChips,
  },
  data() {
    return {
      storage: {
        used: "",
        total: "",
      },
      showScans: [0] as any,
      searchQuery: "" as string,
      fileTypes: [] as IScanFileType[],
      scans: [] as IScan[],
      focusedScan: {} as IScan & {
        files: (IScanDetail & { selected: boolean })[];
      },
      focusedFile: {} as IScanDetail,
      filter: false,
    };
  },
  computed: {
    filteredScans: function () {
      const vm = this as any;
      vm.showScans = [0];
      return (vm.scans as IScan[]).filter((scan) => {
        return (
          scan.scan_description.includes(vm.searchQuery) &&
          vm.$store.state.scansFilter.filteredScans.find(
            (filterScan: IScan) => filterScan.id === scan.id
          )
        );
      });
    },
    selectedScanIds: function () {
      const ids: number[] = [];
      this.filteredScans.forEach((scan: any) => {
        if (scan.selected) {
          ids.push(scan.id);
        }
      });
      return ids;
    },
  },
  created() {
    this.getStorageInfo();
    this.getScans();
    this.getFileTypes();
  },
  methods: {
    async getStorageInfo() {
      try {
        const response = await storage.get();
        this.storage.used = response.data.storage_used;
        this.storage.total = response.data.storage_limit;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async getScans() {
      try {
        const scansResponse = await scans.getAllLatestScans();
        this.scans = scansResponse.data.map((scan) => ({
          ...scan,
          selected: false,
        }));
        this.$store.dispatch(ScansFilterActions.FilterScans, this.scans);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async getFileTypes() {
      try {
        const fileTypes = await scans.fileType.get();
        this.fileTypes = fileTypes.data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async getFocusedScan(id: number, force?: boolean) {
      if (id === this.focusedScan.id && !force) {
        this.focusedScan = {} as any;
        return;
      }
      this.showScans = false;
      try {
        const scan = await scans.get(id);
        // this.focusedScan = {
        //   ...scan.data.scan,
        //   files: scan.data.scan_details.map((file) => ({
        //     ...file,
        //     selected: false,
        //   })),
        // }; //changed
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async updateScan(description: string) {
      try {
        await scans.update(this.focusedScan.id, description);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
      this.getScans();
      this.getFocusedScan(this.focusedScan.id, true);
    },
    getFilesByType(typeId: number) {
      return this.focusedScan.files.filter(
        (file) => file.scan_file_type.id === typeId
      );
    },
    async downloadScans() {
      this.$store.dispatch(ScansActions.Download, this.selectedScanIds);
    },
    async deleteScans() {
      await this.$store.dispatch(ScansActions.Delete, this.selectedScanIds);
      this.getScans();
      this.getStorageInfo();
    },
    // SCAN FILE
    getFocusedFile(id: number, force: boolean) {
      if (id === this.focusedFile.id && !force) {
        this.focusedFile = {} as any;
        return;
      }
      this.focusedFile =
        this.focusedScan.files.find((file) => file.id === id) || ({} as any);
    },
    getSelectedFileIds(fileTypeId: number) {
      const ids: number[] = [];
      this.focusedScan.files.forEach((file) => {
        if (file.selected && file.scan_file_type.id === fileTypeId) {
          ids.push(file.id);
        }
      });
      return ids;
    },
    async downloadFiles(fileTypeId: number) {
      this.$store.dispatch(
        ScansActions.DownloadFiles,
        this.getSelectedFileIds(fileTypeId)
      );
    },
    async deleteFiles(fileTypeId: number) {
      await this.$store.dispatch(
        ScansActions.DeleteFile,
        this.getSelectedFileIds(fileTypeId)
      );
      this.getFocusedScan(this.focusedScan.id, true);
      this.getStorageInfo();
    },
    // FILTER
    toggleDrawer(isOpen: boolean) {
      this.filter = isOpen;
    },
  },
});
